<template>
  <div v-if='isEditing'>
    <v-container fluid>
      <v-tabs v-model='tab' background-color='transparent'>
        <v-tab>Geral</v-tab>
        <v-tab>Serviços</v-tab>
      </v-tabs>
      <v-container fluid>
        <v-tabs-items v-model='tab' class='transparent'>
          <v-tab-item>
            <v-row no-gutters justify='center'>
              <customers-form :is-editing='isEditing' :customer-uuid='customerUuid' v-on='$listeners' />
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <customers-licenses :customer-uuid='customerUuid' />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-container>
  </div>
  <div v-else>
    <v-container fluid fill-height style='height: 90vh'>
      <v-row justify='center' align-center>
        <customers-form :is-editing='isEditing' :customer-uuid='customerUuid' v-on='$listeners' />
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'CustomersDetails',
    components: {
      CustomersForm: () => import('./CustomersForm'),
      // CustomersSpecifications: () => import('./CustomersSpecifications'),
      CustomersLicenses: () => import('./CustomersLicenses'),
    },
    props: {
      isEditing: Boolean,
      customerUuid: {
        type: String,
        default: '',
      },
    },
    data: function () {
      return {
        tab: null,
      };
    },
  };
</script>
