<template>
  <v-container fill-height fluid class='pa-0'>
    <v-row no-gutters align='center' justify='center'>
      <v-card tile flat :width='1300' color='white'>
        <v-row no-gutters align='center' justify='center'>
          <v-card-text>
            <v-form>
              <v-row>
                <v-alert class='mt-3 ml-3' type='warning' color='warning'>As cores alteradas nesta configuração serão aplicadas momentaneamente neste sistema apenas para fins de exemplificação</v-alert>
                <v-col cols='4' class='color-column pb-0'>
                  <v-card class='color-card'>
                    <v-card-title :style='{ padding: "10px", paddingBottom: "30px"}'>
                      Opções de cores
                    </v-card-title>
                    <v-divider :style='{ marginBottom: "40px"}' />
                    <v-row>
                      <v-col cols='12'>
                        <p class='color-label'>Cor Primária</p>
                        <v-row class='color-row'>
                          <v-btn icon large elevation='8' @click='triggerColorPicker("color1")'>
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <div class='color-picker-container'>
                            <input ref='color1' v-model='color1' type='color' class='color-picker' @input='updatePrimaryColor'>
                          </div>
                          <v-icon class='color-pallete' x-large :style='{ color: color1 }'>mdi-palette-swatch-variant</v-icon>
                        </v-row>
                      </v-col>
                      <v-col cols='12'>
                        <p class='color-label'>Cor Secundária</p>
                        <v-row class='color-row'>
                          <v-btn icon large elevation='8' @click='triggerColorPicker("color2")'>
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <div class='color-picker-container'>
                            <input ref='color2' v-model='color2' type='color' class='color-picker' @input='updateSecondaryColor'>
                          </div>
                          <v-icon class='color-pallete' x-large :style='{ color: color2 }'>mdi-palette-swatch-variant</v-icon>
                        </v-row>
                      </v-col>
                      <v-col cols='12'>
                        <p class='color-label'>Cor de destaque</p>
                        <v-row class='color-row'>
                          <v-btn icon large elevation='8' @click='triggerColorPicker("color3")'>
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <div class='color-picker-container'>
                            <input ref='color3' v-model='color3' type='color' class='color-picker' @input='updateAccentColor'>
                          </div>
                          <v-icon class='color-pallete' x-large :style='{ color: color3 }'>mdi-palette-swatch-variant</v-icon>
                        </v-row>
                      </v-col>
                      <v-col cols='12'>
                        <p class='color-label'>Cor de fundo</p>
                        <v-row class='color-row'>
                          <v-btn icon large elevation='8' @click='triggerColorPicker("color4")'>
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <div class='color-picker-container'>
                            <input ref='color4' v-model='color4' type='color' class='color-picker' @input='updateBackgroundColor'>
                          </div>
                          <v-icon class='color-pallete' x-large :style='{ color: color4 }'>mdi-palette-swatch-variant</v-icon>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols='8' class='pb-0'>
                  <v-card class='logo-card'>
                    <v-card-title :style='{ padding: "10px", paddingBottom: "30px"}'>
                      Imagem da logo
                    </v-card-title>
                    <v-divider :style='{ marginBottom: "40px"}' />
                    <v-row>
                      <v-col cols='12' class='d-flex align-center justify-center' style='height: 160px;'>
                        <v-file-input
                          v-model='image'
                          label='Upload de Imagem'
                          accept='image/*'
                          @change='onFileChange'
                        />
                      </v-col>
                      <v-col cols='12' class='d-flex justify-center'>
                        <img :src='logoUrl || openbioLogoUrl' alt='Imagem Carregada' class='uploaded-image'>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class='card-actions'>
            <v-col cols='2' class='card-button-column'>
              <v-btn depressed block color='secondary' class='card-button' @click='returnToDefault'>
                {{ $t('RETURN_TO_DEFAULT') }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols='2' class='card-button-column'>
              <v-btn depressed block color='secondary' outlined class='card-button' :disabled='!changes' @click='resetPreviousSettings'>
                {{ $t('CANCEL') }}
              </v-btn>
            </v-col>
            <v-col cols='2' class='card-button-column'>
              <v-btn depressed block color='secondary' class='card-button' :disabled='!changes' @click='saveSettings(false)'>
                {{ $t('SAVE') }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mapMutations, mapGetters } from 'vuex';

  export default {
    name: 'CustomerTheme',
    props: {
      infant: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      infant: function () {
        this.initializeDefaultCollectFlow();
      },
    },
    data: function () {
      return {
        selectedTab: 0,

        color1: '',
        color2: '',
        color3: '',
        color4: '',
        image: null,
        imageUrl: '../../assets/openbio-logo.png',
        previusColor1: '',
        previusColor2: '',
        previusColor3: '',
        previusColor4: '',
        previusImageUrl: '',
        imageOpenbio: '../../assets/openbio-logo.png',
        changeColor1: '',
        changeColor2: '',
        changeColor3: '',
        changeColor4: '',
        changeImageUrl: '',
        changes: false,
        openbio: false,
      };
    },
    computed: {
      ...mapGetters('auth', ['getUser']),
      ...mapGetters('customTheme', ['logoUrl', 'themeColors', 'openbioThemeColors', 'openbioLogoUrl']),
    },
    created () {
      this.initializeComponent();
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    mounted () {
      this.verifyOpenbioTheme();
    },
    methods: {
      ...mapMutations('customTheme', ['setLogoUrl', 'setThemeColors']),
      handleBeforeUnload (event) {
        if (this.changes) {
          const message = 'Você tem alterações não salvas. Tem certeza que quer sair?';

          this.color1 = this.previusColor1;
          this.color2 = this.previusColor2;
          this.color3 = this.previusColor3;
          this.color4 = this.previusColor4;
          this.imageUrl = this.previusImageUrl;

          this.setLogoUrl(this.imageUrl);
          this.updatePrimaryColor();
          this.updateSecondaryColor();
          this.updateAccentColor();
          this.updateBackgroundColor();
          this.updateThemeColors();

          event.returnValue = message;

          return message;
        }

        return undefined;
      },
      convertToBase64 (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.base64Image = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      onFileChange () {
        this.changes = true;
        const file = this.image;
        if (file) {
          this.imageUrl = URL.createObjectURL(file);
          this.setLogoUrl(this.imageUrl);
          this.convertToBase64(file);
          this.openbio = false;
        }
      },
      initializeComponent () {
        this.color1 = this.themeColors.primary || this.openbioThemeColors.primary;
        this.color2 = this.themeColors.secondary || this.openbioThemeColors.secondary;
        this.color3 = this.themeColors.accent || this.openbioThemeColors.accent;
        this.color4 = this.themeColors.background || this.openbioThemeColors.background;
        this.imageUrl = this.logoUrl || this.openbioLogoUrl;

        this.previusColor1 = this.color1;
        this.previusColor2 = this.color2;
        this.previusColor3 = this.color3;
        this.previusColor4 = this.color4;
        this.previusImageUrl = this.imageUrl;
      },
      updatePrimaryColor () {
        this.changes = true;
        this.$vuetify.theme.themes.light.primary = this.color1;
        this.updateThemeColors();
      },
      updateSecondaryColor () {
        this.changes = true;
        this.$vuetify.theme.themes.light.secondary = this.color2;
        this.updateThemeColors();
      },
      updateAccentColor () {
        this.changes = true;
        this.$vuetify.theme.themes.light.accent = this.color3;
        this.updateThemeColors();
      },
      updateBackgroundColor () {
        this.changes = true;
        this.$vuetify.theme.themes.light.background = this.color4;
        this.updateThemeColors();
      },
      triggerColorPicker (refName) {
        this.$refs[refName].click();
      },
      updateThemeColors () {
        const theme = {
          primary: this.color1,
          secondary: this.color2,
          accent: this.color3,
          background: this.color4,
          info: this.$store.state.customTheme.info,
          warning: this.$store.state.customTheme.warning,
          error: this.$store.state.customTheme.error,
          success: this.$store.state.customTheme.success,
        };
        this.setThemeColors(theme);
        this.openbio = false;
      },
      verifyOpenbioTheme () {
        if (this.color1 === this.openbioThemeColors.primary
          && this.color2 === this.openbioThemeColors.secondary
          && this.color3 === this.openbioThemeColors.accent
          && this.color4 === this.openbioThemeColors.background
          && this.imageUrl.includes('openbio-logo')) {
          this.openbio = true;
        }
      },
      returnToDefault: async function () {
        Swal.fire({
          title: 'Você tem certeza que deseja voltar nas configurações padrão ?',
          text: 'O layout voltará para as configurações do layout Openbio',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.changeColor1 = this.color1;
            this.changeColor2 = this.color2;
            this.changeColor3 = this.color3;
            this.changeColor4 = this.color4;
            this.changeImageUrl = this.imageUrl;
            this.color1 = this.openbioThemeColors.primary;
            this.color2 = this.openbioThemeColors.secondary;
            this.color3 = this.openbioThemeColors.accent;
            this.color4 = this.openbioThemeColors.background;
            this.image = null;
            this.imageUrl = require('../../assets/openbio-logo.png');
            this.setLogoUrl();

            this.updatePrimaryColor();
            this.updateSecondaryColor();
            this.updateAccentColor();
            this.updateBackgroundColor();

            this.saveSettings(true);
            this.openbio = true;
          }
        });
      },
      resetPreviousSettings () {
        Swal.fire({
          title: 'Você tem certeza que deseja cancelar as alterações ?',
          text: 'O layout voltará para as configurações anteriormente aplicadas',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.color1 = this.previusColor1;
            this.color2 = this.previusColor2;
            this.color3 = this.previusColor3;
            this.color4 = this.previusColor4;
            this.imageUrl = this.previusImageUrl;
            this.setLogoUrl(this.imageUrl);
            this.updatePrimaryColor();
            this.updateSecondaryColor();
            this.updateAccentColor();
            this.updateBackgroundColor();

            this.updateThemeColors();

            this.changes = false;
            this.verifyOpenbioTheme();
          }
        });
      },
      saveSettings: async function (returnToDefault) {
        const user = this.getUser;
        let theme = {};

        if (!returnToDefault) {
          theme = {
            primary: this.color1,
            secondary: this.color2,
            accent: this.color3,
            background: this.color4,
            logo: this.base64Image,
            info: this.$store.state.customTheme.info,
            warning: this.$store.state.customTheme.warning,
            error: this.$store.state.customTheme.error,
            success: this.$store.state.customTheme.success,
          };
        }

        Swal.fire({
          title: 'Você tem certeza?',
          text: 'Deseja salvar as alterações?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Salvar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await axios({
                url: `/customer/${user.account.customerUuid}/configuration/theme`,
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'POST',
                responseType: 'json',
                withCredentials: true,
                data: {
                  data: theme,
                  infant: this.infant,
                },
              });
              Swal.fire({
                title: 'Salvo!',
                text: 'As alterações foram salvas com sucesso.',
                icon: 'success',
              });
              this.changes = false;

              this.changeColor1 = this.color1;
              this.changeColor2 = this.color2;
              this.changeColor3 = this.color3;
              this.changeColor4 = this.color4;
              this.changeImageUrl = this.imageUrl;
              this.color1 = this.openbioThemeColors.primary;
              this.color2 = this.openbioThemeColors.secondary;
              this.color3 = this.openbioThemeColors.accent;
              this.color4 = this.openbioThemeColors.background;
              this.image = null;
              this.imageUrl = require('../../assets/openbio-logo.png');
              this.setLogoUrl();

              this.updatePrimaryColor();
              this.updateSecondaryColor();
              this.updateAccentColor();
              this.updateBackgroundColor();
              this.openbio = true;
            } catch (error) {
              Swal.fire({
                title: 'Erro!',
                text: 'Ocorreu um erro ao salvar as alterações.',
                icon: 'error',
              });
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.color1 = this.changeColor1;
            this.color2 = this.changeColor2;
            this.color3 = this.changeColor3;
            this.color4 = this.changeColor4;
            this.imageUrl = this.changeImageUrl;
            this.setLogoUrl(this.imageUrl);

            this.updatePrimaryColor();
            this.updateSecondaryColor();
            this.updateAccentColor();
            this.updateBackgroundColor();
          }
        });
      },
    },
    beforeRouteLeave (to, from, next) {
      if (this.previusColor1 !== this.color1 || this.previusColor2 !== this.color2 || this.previusColor3 !== this.color3 || this.previusColor4 !== this.color4 || this.previusImageUrl !== this.imageUrl) {
        this.changes = true;
      }
      if (this.changes === true) {
        Swal.fire({
          title: 'Tem certeza?',
          text: 'Você tem alterações não salvas. Tem certeza que quer sair?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.color1 = this.previusColor1;
            this.color2 = this.previusColor2;
            this.color3 = this.previusColor3;
            this.color4 = this.previusColor4;
            this.imageUrl = this.previusImageUrl;
            this.setLogoUrl(this.imageUrl);
            this.updatePrimaryColor();
            this.updateSecondaryColor();
            this.updateAccentColor();
            this.updateBackgroundColor();
            this.updateThemeColors();
            next(); // Permitir a navegação
          }
        });
      } else {
        next(); // Permitir a navegação se não houver alterações não salvas
      }
    },
  };
</script>
<style scoped>
.color-picker-container {
  display: inline-block;
  width: 0%;
  overflow: hidden; /* Garante que o input se ajuste ao contêiner */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Sombra para destacar */
  padding: 0;
}

.color-picker {
  border: none;
  padding: 0;
  margin-top: -100px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 8px !important;
  background-color: transparent;
  outline: none;
}
.color-row {
  padding: 20px;
}
.color-pallete {
  margin-left: 64%;
}
.color-label {
  margin-bottom: 8px;
  font-weight: bold;
}

.card-button {
  font-size: 0.7vw;
}

.logo-card {
  padding: 10px;
  padding-bottom: 20px;
  height: 100%;
}
.color-card {
  padding: 10px;
  padding-bottom: 20px;
  height: 100%;
}

.card-actions {
  width: 100%;
  justify-content: flex-end;
}

.card-button {
  width: 30% !important;
  margin-right: 10px;
}

.uploaded-image {
  max-width: 400px;
  max-height: 250px;
  margin-top: 10px;
}

h4 {
  margin-bottom: 60px;
  font-weight: bold;
  font-size: 20px;
  opacity: 0.8;
}

</style>
