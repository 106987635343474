import { ActionContext } from 'vuex';
import axios from 'axios';
import { RootState } from '@/store';
// import vuetify from '@/plugins/vuetify';

export type ThemeStateType = {
  logoUrl: string,
  primary: string,
  secondary: string,
  accent: string,
  background: string,
  info: string,
  warning: string,
  error: string,
  success: string,
};

export type OpenbioStateType = {
  openbioLogo: string,
  openbioPrimary: string,
  openbioSecondary: string,
  openbioAccent: string,
  openbioBackground: string,
  openbioInfo: string,
  openbioWarning: string,
  openbioError: string,
  openbioSuccess: string,
};

export type StateType = {
  customTheme: ThemeStateType,
  openbio: OpenbioStateType,
};

const mutations = {
  setLogoUrl (state: StateType, url: string): void {
    state.customTheme.logoUrl = url;
  },
  setThemeColors (state: StateType, theme: any): void {
    state.customTheme.primary = theme.primary;
    state.customTheme.secondary = theme.secondary;
    state.customTheme.accent = theme.accent;
    state.customTheme.background = theme.background;
    state.customTheme.info = theme.info || state.customTheme.info;
    state.customTheme.warning = theme.warning || state.customTheme.warning;
    state.customTheme.error = theme.error || state.customTheme.error;
    state.customTheme.success = theme.success || state.customTheme.success;
    localStorage.setItem('theme', JSON.stringify(theme));

    state.openbio.openbioLogo = require('@/assets/openbio-logo.png');
  },
};

type LogoActionContext = ActionContext<StateType, RootState>;

const actions = {
  async fetchTheme ({ commit }: LogoActionContext, customerUuid: string): Promise<void> {
    try {
      const { data } = await axios({
        url: `/customer/${customerUuid}`,
        baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
        method: 'GET',
        responseType: 'json',
        withCredentials: true,
      });
      if (data.customer?.theme) {
        commit('setThemeColors', data.customer.theme);
        commit('setLogoUrl', data.customer.theme.logo);
      }
    } catch (e) {
      console.log(e); /* eslint-disable-line no-console */
    }
  },
  loadThemeFromLocalStorage ({ commit }: LogoActionContext): void {
    const theme = localStorage.getItem('theme');
    if (theme) {
      commit('setThemeColors', JSON.parse(theme));
    }
  },
};

const getters = {
  logoUrl (state: StateType): string {
    return state.customTheme.logoUrl;
  },
  openbioLogoUrl (state: StateType): string {
    return state.openbio.openbioLogo;
  },
  themeColors (state: StateType): {
    primary: string,
    secondary: string,
    accent: string,
    background: string,
    info: string,
    warning: string,
    error: string,
    success: string,
  } {
    return {
      primary: state.customTheme.primary,
      secondary: state.customTheme.secondary,
      accent: state.customTheme.accent,
      background: state.customTheme.background,
      info: '#2196F3',
      warning: '#FFB300',
      error: '#F44336',
      success: '#4CAF50',
    };
  },
  openbioThemeColors (state: StateType): {
    openbioLogo: string,
    primary: string,
    secondary: string,
    accent: string,
    background: string,
    info: string,
    warning: string,
    error: string,
    success: string,
  } {
    return {
      openbioLogo: state.openbio.openbioLogo,
      primary: state.openbio.openbioPrimary,
      secondary: state.openbio.openbioSecondary,
      accent: state.openbio.openbioAccent,
      background: state.openbio.openbioBackground,
      info: state.openbio.openbioInfo,
      warning: state.openbio.openbioWarning,
      error: state.openbio.openbioError,
      success: state.openbio.openbioSuccess,
    };
  },
};

const state = (): StateType => ({
  customTheme: {
    logoUrl: '',
    primary: '#380281',
    secondary: '#7919FA',
    accent: '#e1d1f6',
    background: '#f2edf9',
    info: '#2196F3',
    warning: '#FFB300',
    error: '#F44336',
    success: '#4CAF50',
  },
  openbio: {
    openbioLogo: require('@/assets/openbio-logo.png'),
    openbioPrimary: '#380281',
    openbioSecondary: '#7919FA',
    openbioAccent: '#e1d1f6',
    openbioBackground: '#f2edf9',
    openbioInfo: '#2196F3',
    openbioWarning: '#FFB300',
    openbioError: '#F44336',
    openbioSuccess: '#4CAF50',
  },
});

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
