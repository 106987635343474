<template>
  <v-container fluid>
    <v-card flat class='pa-5'>
      <v-row no-gutters>
        <v-btn class='mb-3' color='success' @click='saveCustomerServices()'> Salvar </v-btn>
        <v-spacer />
        <v-btn class='mb-3' color='primary' outlined @click='openLicenseGenerationDialog()'> Gerar licenças </v-btn>
        <!-- <v-btn class='mb-3' color='primary' outlined @click='validate()'> Validar licença (teste) </v-btn> -->
      </v-row>

      <h4>Habilitados</h4>
      <CustomerService
        :key='`available_${customerServices.length}`'
        :services='customerServices'
        @renew-license='changeLicenseStatus("Renew", $event)'
        @revoke-license='changeLicenseStatus("Revoke", $event)'
        @download-license='downloadLicense'
      />

      <h4 class='mt-3'>Não habilitados</h4>
      <CustomerService :key='`unavailable_${customerUnavailableServices.length}`' :services='customerUnavailableServices' />
    </v-card>

    <v-dialog v-model='licenseGenerationDialog' max-width='650' persistent no-click-animation>
      <v-card class='b-radius-15 px-2 py-4'>
        <v-card-title class='color-secondary'>Selecione os serviços a gerar licença</v-card-title>
        <v-card-text class='py-2'>
          <v-list-item v-for='(service, index) in customerServices.filter((cs) => ["Infant", "Openbio Enroll"].includes(cs.name))' :key='index' class='px-0' two-line>
            <v-card width='100%' class='mb-3 box-shadow-x-bottom'>
              <v-list-item-content class='py-0'>
                <v-list-item-title class='px-3'>
                  <v-checkbox v-model='service.selected' color='primary' :label='service.name' />
                </v-list-item-title>
                <v-list-item-subtitle v-if='service.license && isLicenseExpired(service.expirationDate)'>
                  <v-alert class='text-center mb-0' color='warning' dense text style='border-radius: 0'>
                    <v-icon color='warning' class='mr-2'>mdi-alert-circle-outline</v-icon>
                    Este serviço já possui uma licença em vigência até {{ formatDate(service.expirationDate) }}
                  </v-alert>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-card>
          </v-list-item>
          <v-footer color='transparent' class='px-0'>
            <v-spacer />
            <v-btn class='mr-2' color='secondary' outlined @click='closeLicenseDialog()'>Cancelar</v-btn>
            <v-btn color='success' :disabled='customerServices.filter((cs) => cs.selected).length === 0' @click='validateExistentLicenses()'>Gerar</v-btn>
          </v-footer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { formattedDate } from '../../util/date';

  export default {
    name: 'CustomersLicenses',
    props: {
      customerUuid: {
        type: String,
        default: '',
      },
    },
    components: {
      CustomerService: () => import('../../components/Customers/CustomerService.vue'),
    },
    computed: {
      customerUnavailableServices: function () {
        const customerServicesMap = this.customerServices.map((cs) => cs.id);
        return this.services.filter((service) => !customerServicesMap.includes(service.id));
      },
    },
    data: function () {
      return {
        services: [],
        customerServices: [],
        licenseGenerationDialog: false,
      };
    },
    mounted: async function () {
      this.getAvailableServices();
      this.getCustomerServices();
    },
    methods: {
      formatDate: function (stringDate) {
        return formattedDate(stringDate, 'dd/MM/yyyy');
      },
      getAvailableServices: async function () {
        const { data } = await axios({
          url: '/account-get-available-modules',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.services = data.list;
      },
      getCustomerServices: async function () {
        const { data } = await axios({
          url: `/customer/${this.customerUuid}/available-services`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.customerServices = data.services;
      },
      saveCustomerServices: async function () {
        let services = this.customerServices;
        services = services.concat(this.customerUnavailableServices);
        services = services.filter((s) => s.enabled);

        let validation = true;
        const validationErrors = [];

        services.forEach((service) => {
          if (!service.expirationDate) {
            validationErrors.push(`<li>O serviço <strong style='color: ${this.$vuetify.theme.defaults.light.secondary}'>${service.name}</strong> não possui data de limite de contratação definida. Verifique e salve novamente.</li>`);
            validation = false;
          }
          if (service.specifications && !service.allowBlankSpecifications) {
            let hasSpec = false;
            service.specifications.forEach((spec) => {
              if (spec.enabled) {
                hasSpec = true;
              }
            });
            if (!hasSpec) {
              validationErrors.push(`<li>O serviço <strong style='color: ${this.$vuetify.theme.defaults.light.secondary}'>${service.name}</strong> não possui nenhuma especificação definida. Verifique e salve novamente.</li>`);
              validation = false;
            }
          }
        });

        if (!validation && validationErrors.length) {
          Swal.fire({
            icon: 'warning',
            title: 'Aviso',
            html: `<ul>${validationErrors.join('')}</ul>`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });
          return;
        }

        await axios({
          url: `/customer/${this.customerUuid}/available-services`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'POST',
          data: {
            services,
          },
          responseType: 'json',
          withCredentials: true,
        });

        Swal.fire({
          icon: 'success',
          title: 'Serviços salvos',
          text: 'Os serviços e especificações selecionadas foram vinculados ao cliente com sucesso',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true,
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        });

        this.getAvailableServices();
        this.getCustomerServices();
      },
      openLicenseGenerationDialog: function () {
        this.licenseGenerationDialog = true;
      },
      validateExistentLicenses: function () {
        const selectedServices = this.customerServices.filter((cs) => cs.selected);
        const licensedServices = selectedServices.filter((ss) => ss.license && this.isLicenseExpired(ss.expirationDate));

        if (licensedServices.length) {
          Swal.fire({
            icon: 'warning',
            title: 'Aviso',
            html: `
              <div>
                <p>Os serviços abaixo já possuem licença vigente:</p>
                <br/>
                <p>${licensedServices.map((ls) => `• ${ls.name}<br/>`).join('')}</p>
                <br/>
                Deseja mesmo gerar as licenças para estes serviço novamente?
              </div>
            `,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonText: this.$t('YES'),
            reverseButtons: true,
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          }).then((result) => {
            if (result.value) {
              this.generateLicenses();
            }
          });
        } else {
          this.generateLicenses();
        }
      },
      generateLicenses: function () {
        const selectedServices = this.customerServices.filter((cs) => cs.selected);
        Swal.fire({
          icon: 'warning',
          title: 'Confirmação',
          html: `
            <div>
              <p>Certifique-se de que as opções selecionadas estão corretas</p>
              <br/>
              <p style='text-align: left'>
                ${selectedServices.map((ss) => `
                  <span>• ${ss.name}</span><br/>
                  ${ss.specifications && ss.specifications.filter((s) => s.enabled || s.amount).map((s) => `<span style='margin-left: 15px'>◦ ${s.name} ${s.amount || ''}</span><br/>`).join('')}
                `).join('<br/>')}
                <br/>
              </p>
              <br/>
              Os serviços e especificações estão corretos?
            </div>
          `,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonText: this.$t('CONFIRM'),
          reverseButtons: true,
          confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
        }).then(async (result) => {
          if (result.value) {
            const services = this.customerServices.filter((cs) => cs.selected);

            await axios({
              url: `/customer/${this.customerUuid}/generate-licenses`,
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'POST',
              data: {
                services,
              },
              responseType: 'json',
              withCredentials: true,
            });

            Swal.fire({
              icon: 'success',
              title: 'Licenças geradas',
              text: 'As licenças para os serviços selecionados foram geradas com sucesso',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: true,
              confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
            });

            this.closeLicenseDialog();
            this.getAvailableServices();
            this.getCustomerServices();
          }
        });
      },
      isLicenseExpired: function (expirationDate) {
        const today = new Date();
        const licenseExpirationDate = new Date(expirationDate);
        return today.getTime() < licenseExpirationDate.getTime();
      },
      closeLicenseDialog: function () {
        this.licenseGenerationDialog = false;
        this.customerServices.forEach((cs) => {
          cs.selected = false;
        });
      },
      changeLicenseStatus: async function (action, service) {
        await axios({
          url: `/customer/${this.customerUuid}/license/change-status`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'POST',
          data: {
            service,
            action,
          },
          responseType: 'json',
          withCredentials: true,
        });
        this.getAvailableServices();
        this.getCustomerServices();
      },
      downloadLicense: function ({ serviceName, serviceLicense }) {
        const fileName = `${serviceName}.lic`;
        const fileType = 'text/plain';

        const blob = new Blob([serviceLicense], { type: fileType });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      },
    },
  };
</script>

<style>
</style>
