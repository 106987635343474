<template>
  <v-container fluid fill-height style='height: 90vh' class='pa-0'>
    <v-card flat width='100%' height='100%'>
      <v-tabs v-model='tab' show-arrows>
        <v-tab>
          {{isEditing ? $t('EDIT_PERMISSION_GROUP') : $t('NEW_PERMISSION_GROUP')}}
        </v-tab>
        <v-tab v-if='isEditing'>
          Gerenciar serviços
        </v-tab>
        <v-tab v-if='isEditing'>
          Gerenciar usuários
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model='tab'>
        <!--
        # #################################
        # General Information
        # ################################# -->
        <v-tab-item>
          <div class='pa-5'>
            <v-form ref='form' v-model='formIsValid' lazy-validation>
              <v-text-field
                v-model='permissionGroup.name'
                :rules='[rules.required]'
                :label='$t("NAME")'
                clearable
              />
              <v-textarea
                v-model='permissionGroup.description'
                :rules='[rules.counter]'
                :label='$t("DESCRIPTION")'
                rows='3'
                maxlength='100'
                counter
                no-resize
                clearable
              />
            </v-form>
            <v-btn block color='secondary' class='mt-8' @click='handleOnConfirm'>
              {{$t('CONFIRM')}}
            </v-btn>
          </div>
        </v-tab-item>
        <!--
        # #################################
        # Services
        # ################################# -->
        <v-tab-item v-if='isEditing'>
          <v-card flat class='px-10'>
            <v-card-text class='mt-3 px-0'>
              <v-row>
                <v-col
                  v-for='service in customerServices'
                  :key='service.id'
                  class='px-5 mr-5 mb-5 elevation-1'
                  :style='{
                    minWidth: $vuetify.breakpoint.mdAndDown ? "100%" : undefined,
                    maxWidth: $vuetify.breakpoint.mdAndDown ? "100%" : undefined
                  }'
                >
                  <v-col cols='12' class='d-flex align-center justify-center'>
                    <v-img class='mr-3' :src='getImageUrl(service.image)' max-width='48' contain />
                    {{ service.name }}
                  </v-col>
                  <v-divider class='mt-2' />
                  <v-col cols='12' class='d-flex align-center justify-center pt-0'>
                    <v-checkbox v-model='service.enabled' label='Habilitado' color='primary' />
                  </v-col>
                  <v-col v-if='service.specifications && service.specifications.length' cols='12' class='pt-0'>
                    <v-col cols='12' class='d-flex align-center justify-center pt-0'>
                      <span class='title-primary'> Especificações </span>
                    </v-col>
                    <v-checkbox v-for='spec in service.specifications' :key='spec.code' v-model='spec.enabled' :label='spec.name' color='primary' />
                  </v-col>
                </v-col>
              </v-row>
              <v-btn block color='secondary' class='mt-5' @click='saveGroupServices'>
                {{$t('CONFIRM')}}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!--
        # #################################
        # Users
        # ################################# -->
        <v-tab-item v-if='isEditing'>
          <v-card flat>
            <v-card-text>
              <v-row no-gutters>
                <v-text-field v-model='userSearch' label='Busca' class='mr-5' style='max-width: 400px' hide-details />
              </v-row>
              <v-row v-for='user in compCustomerUsers' :key='user.id' no-gutters class='elevation-1 px-5 py-2 mt-5'>
                <v-checkbox v-model='user.enabled' :label='user.name' />
              </v-row>
              <v-btn block color='secondary' class='mt-5' @click='saveGroupUsers'>
                {{$t('CONFIRM')}}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import { getImageUrl } from '../../util/image';

  export default {
    name: 'PermissionGroupDetails',
    props: {
      edit: Boolean,
      permissionGroupId: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapGetters('auth', ['getUser']),
      compCustomerUsers: function () {
        if (this.userSearch) {
          return this.customerUsers.filter((cUser) => cUser.name.toLowerCase().indexOf(this.userSearch.toLowerCase()) >= 0);
        }
        return this.customerUsers;
      },
    },
    mounted () {
      if (this.edit) {
        this.isEditing = this.edit;
        this.getDataById(this.permissionGroupId);
      }
      if (this.permissionGroupId) {
        this.getCustomerServices();
        this.getCustomerUsers();
      }
    },
    data: function () {
      return {
        isEditing: false,
        formIsValid: false,
        showPassword: false,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
          counter: (value) => (value && value.length) <= 100 || this.$t('MAX_CHAR', { value: 100 }),
        },
        tab: null,
        permissionGroup: {
          id: undefined,
          name: undefined,
          description: undefined,
        },
        userSearch: '',
        customerServices: [],
        customerUsers: [],
      };
    },
    methods: {
      getImageUrl,
      getCustomerServices: async function () {
        const { data } = await axios({
          url: `/customer/${this.getUser.account.customerUuid}/available-services`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.customerServices = data.services;
        this.customerServices.forEach((service) => {
          service.enabled = false;
          if (service.specifications?.length) {
            service.specifications = service.specifications.filter((spec) => spec.enabled);
            service.specifications.forEach((spec) => {
              spec.enabled = false;
            });
          }
        });
        this.getGroupServices();
      },
      getCustomerUsers: async function () {
        const { data } = await axios({
          url: `/account/customer/${this.getUser.account.customerUuid}`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });

        this.customerUsers = data.list;

        if (this.customerUsers?.length) {
          this.getGroupUsers();
        }
      },
      getGroupUsers: async function () {
        const { data } = await axios({
          url: `/permission-group-user/${this.permissionGroup.id || this.permissionGroupId}`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });

        const groupUsers = data && data.permissionGroupUsers;
        if (groupUsers && groupUsers.length) {
          const groupUserMap = new Map();
          groupUsers.forEach((gu) => {
            groupUserMap.set(gu.id, gu.id);
          });

          this.customerUsers.forEach((cUser) => {
            cUser.enabled = groupUserMap.has(cUser.id);
          });
        }
      },
      togglePasswordVisibility: function () {
        this.showPassword = !this.showPassword;
      },
      handleOnConfirm: function () {
        if (!this.formIsValid) {
          this.$refs.form.validate();
          return;
        }

        if (this.isEditing) {
          this.update();
        } else {
          this.save();
        }
      },
      getDataById: async function (id) {
        try {
          const { data } = await axios({
            url: `/permission-group-info/${id}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.permissionGroup = data && data.permissionGroup;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      save: async function () {
        try {
          const { name, description } = this.permissionGroup;
          const { data } = await axios({
            url: `/permission-group/${this.getUser.account.customerUuid}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              name,
              description,
            },
          });

          this.$emit('new-permission-group');

          this.isEditing = true;
          this.permissionGroup = data && data.permissionGroup;
          this.getCustomerServices();
          this.getCustomerUsers();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      update: async function () {
        try {
          const { id, name, description } = this.permissionGroup;
          const { data } = await axios({
            url: `/permission-group/${id}`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'PUT',
            responseType: 'json',
            withCredentials: true,
            data: {
              name,
              description,
            },
          });
          this.permissionGroup = data && data.permissionGroup;

          this.$emit('edit-permission-group');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getGroupServices: async function () {
        const { data } = await axios({
          url: `/permission-group-service/${this.permissionGroup.id || this.permissionGroupId}`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });

        const groupServices = data && data.permissionGroupServices;
        if (groupServices && groupServices.length) {
          const groupServicesMap = new Map();
          groupServices.forEach((gs) => {
            groupServicesMap.set(gs.serviceId, gs.specifications);
          });

          this.customerServices.forEach((cs) => {
            cs.enabled = groupServicesMap.has(cs.id);
            if (cs.enabled) {
              cs.specifications = groupServicesMap.get(cs.id);
            }
          });
        }
      },
      saveGroupServices: async function () {
        const body = [];
        for (let i = 0; i < this.customerServices.length; i++) {
          const service = this.customerServices[i];
          if (service.enabled) {
            body.push({
              id: service.id,
              name: service.name,
              specifications: service.specifications,
            });
          }
        }
        await axios({
          url: `/permission-group-service/${this.permissionGroup.id || this.permissionGroupId}`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'POST',
          responseType: 'json',
          withCredentials: true,
          data: {
            groupServices: body,
          },
        });
      },
      saveGroupUsers: async function () {
        const users = [];
        for (let i = 0; i < this.customerUsers.length; i++) {
          const user = this.customerUsers[i];
          if (user.enabled) {
            users.push(user.id);
          }
        }
        await axios({
          url: `/permission-group-user/${this.permissionGroup.id || this.permissionGroupId}`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'POST',
          responseType: 'json',
          withCredentials: true,
          data: {
            users,
          },
        });
      },
    },
  };
</script>
