import { RootState } from '@/store';
import { ActionContext } from 'vuex';

export type LoadingStateType = {
  isLoading: boolean,
  loadingDescription?: string,
};

export default {
  namespaced: true,
  state: (): LoadingStateType => ({
    isLoading: false,
    loadingDescription: '',
  }),
  getters: {
    isLoading: function (state: LoadingStateType): boolean {
      return state.isLoading;
    },
    loadingDescription: function (state: LoadingStateType): string | undefined {
      return state.loadingDescription;
    },
  },
  mutations: {
    setLoading: function (state: LoadingStateType, value: boolean): void {
      state.isLoading = value;
    },
    setDescription: function (state: LoadingStateType, value: string): void {
      state.loadingDescription = value;
    },
  },
  actions: {
    toggleLoading: function ({ commit, getters }: ActionContext<LoadingStateType, RootState>, values: any): void {
      commit('setLoading', values.value !== undefined ? values.value : !getters.isLoading);
      commit('setDescription', values.loadingDescription !== undefined ? values.loadingDescription : '');
    },
  },
};
