<template>
  <v-container fluid>
    <v-row no-gutters class='mt-2'>
      <v-card tile flat color='white' class='wd-100'>
        <v-btn-toggle v-model='type' class='my-2' tile color='primary' group>
          <v-btn v-for='noteType in ["API", "Web"]' :key='noteType' class='font-weight-bold' color='secondary' outlined :value='noteType'> {{ noteType }} </v-btn>
        </v-btn-toggle>

        <v-timeline dense>
          <v-timeline-item v-for='(releaseNote) in releaseNotes' :key='`${type}_${releaseNote}`' color='secondary'>
            <zero-md>
              <script type='text/markdown'>
                {{ releaseNote }}
              </script>
            </zero-md>
          </v-timeline-item>
        </v-timeline>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'ReleaseNotes',
    computed: {
      releaseNotes: function () {
        return this.versions[this.type.toLowerCase()];
      },
    },
    data: function () {
      return {
        type: 'API',
        versions: [],
      };
    },
    mounted: function () {
      this.getData();
    },
    methods: {
      getData: async function () {
        const { data } = await axios({
          url: '/release-notes',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });

        const webReleaseNotes = require('./release-notes.md');
        this.versions = {
          api: data.releaseNotes.split('--'),
          web: webReleaseNotes.split('--'),
        };
        this.$forceUpdate();
      },
    },
  };
</script>
