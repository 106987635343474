<template>
  <v-container fluid>
    <v-row no-gutters>
      <div class='text-h5'> {{$t('FIRST_STEPS')}} </div>
    </v-row>

    <v-divider class='my-3' />

    <v-card class='pa-10'>
      <v-card-text>
        <ol>
          <li>{{ $t('DOWNLOAD_INSTALLER') }}</li>
          <li>{{ $t('INSTALL_SOFTWARE_INFO') }}</li>
          <li>{{ $t('ACCESS_URL_TO_START_INFO') }}</li>
          <li>{{ $t('ENJOY_SOFTWARE_INFO') }}</li>
        </ol>
      </v-card-text>
      <v-card-text v-for='customerService in customerServices' :key='customerService.id'>
        <v-row no-gutters align='center'>
          <v-img class='mr-3' :src='getImageUrl(customerService.image)' max-width='48' contain />
          <span class='mr-3'>{{ customerService.name }}</span>
        </v-row>
        <v-divider class='my-4' />
        <v-data-table
          :headers='getTableHeaders(customerService)'
          :items='customerServices.filter((cs) => cs.id === customerService.id)'
          hide-default-footer
          class='elevation-1'
        >
          <template #item.installer='{}'>
            <v-btn color='success' small @click='downloadInstaller(customerService.name)'>
              <v-icon small class='mr-2'>mdi mdi-download</v-icon>
              Baixar instalador
            </v-btn>
          </template>
          <template #item.appUrl='{ item }'>
            <a style='text-decoration: underline; color: blue' :href='item.appUrl' target='_blank'>{{ $t(item.appUrl) }}</a>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click='copyUrl(item.appUrl)'>
                  <v-icon small class='ml-1 mb-1'> mdi mdi-content-copy </v-icon>
                </v-btn>
              </template>
              {{$t('CLICK_TO_COPY_URL')}}
            </v-tooltip>
          </template>
          <template #item.selfServiceUrl='{ item }'>
            <a style='text-decoration: underline; color: blue' :href='item.selfServiceUrl' target='_blank'>{{ $t(item.selfServiceUrl) }}</a>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click='copyUrl(item.selfServiceUrl)'>
                  <v-icon small class='ml-1 mb-1'> mdi mdi-content-copy </v-icon>
                </v-btn>
              </template>
              {{$t('CLICK_TO_COPY_URL')}}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';

  import { showSuccess } from '@/util/notification';
  import { getImageUrl } from '../../util/image';

  export default {
    name: 'CustomerFirstSteps',
    computed: {
      ...mapGetters('auth', ['getUser']),
    },
    data: function () {
      return {
        serviceTab: 3,
        selectedTab: 0,
        customerServices: [],
        SERVICES: {
          ENROLL: 3,
          INFANT: 5,
        },

      };
    },
    mounted: function () {
      this.getCustomerServices();
    },
    methods: {
      getImageUrl,
      getCustomerServices: async function () {
        const { data } = await axios({
          url: `/customer/${this.getUser.account.customerUuid}/available-services`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });

        this.customerServices = data.services.filter((service) => [this.SERVICES.ENROLL, this.SERVICES.INFANT].includes(service.id));

        if (this.customerServices?.length) {
          this.customerServices.forEach((service) => {
            if (service.id === this.SERVICES.ENROLL) {
              service.appUrl = `${process.env.VUE_APP_ENROLL_URL}`;
              service.selfServiceUrl = `${process.env.VUE_APP_ENROLL_URL}self-service?uuid=${this.getUser.account.customerUuid}`;
            } else {
              service.appUrl = `${process.env.VUE_APP_INFANT_URL}`;
            }
          });
        }
      },
      getTableHeaders: function (service) {
        const headers = [
          {
            text: this.$t('INSTALLER'),
            align: 'center',
            width: '25%',
            value: 'installer',
          },
          {
            text: this.$t('APP_URL'),
            align: 'center',
            width: '25%',
            value: 'appUrl',
          },
        ];
        if (service.id === this.SERVICES.ENROLL && service.selfService) {
          headers.push({
            text: this.$t('SELF_SERVICE_URL'),
            align: 'center',
            value: 'selfServiceUrl',
          });
        } else {
          headers.push({
            text: '',
            sortable: false,
            align: 'center',
          });
        }
        return headers;
      },
      copyUrl: function (url) {
        navigator.clipboard.writeText(url);
        showSuccess({ message: this.$t('URL_COPIED') });
      },
      downloadInstaller: async function (serviceName) {
        this.$store.dispatch('loading/toggleLoading', { value: true });
        try {
          const response = await axios({
            url: `/customer/${this.getUser.account.customerUuid}/installer-download`,
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            params: { serviceName },
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              this.$store.dispatch('loading/toggleLoading', { value: true, loadingDescription: `Download: ${progress.toFixed(2)}%` });
            },
            withCredentials: true,
          });

          const contentDisposition = response.headers['content-disposition'];
          const filename = contentDisposition
            ? contentDisposition.split('filename=')[1].replace(/"/g, '')
            : `${serviceName}.exe`;

          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error during file download:', error); // eslint-disable-line
        } finally {
          this.$store.dispatch('loading/toggleLoading', { value: false });
        }
      },
    },
  };
</script>

<style scoped>
</style>
