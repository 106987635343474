/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import store from '@/store';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { TranslateResult } from 'vue-i18n';
import i18n from '../i18n';

Vue.use(VueRouter);

async function getUser (): Promise<{ details: any, isAdmin: boolean, isOnlySuperAdmin: boolean, menuCodes: string[], }> {
  let user = store.getters['auth/getUser'];
  if (!user || !user.id) {
    await store.dispatch('auth/getLoggedUser');
    user = store.getters['auth/getUser'];
  }
  const { role, menuCodes } = user.account;

  return {
    details: user,
    isAdmin: !!(role && (role.isSuperAdmin || role.isAdmin || role.isSupervisor)),
    isOnlySuperAdmin: role.isSuperAdmin,
    menuCodes: menuCodes,
  };
}

type RouteType = {
  name: string,
  code: string,
  isMenu: boolean,
  isReport?: boolean,
  onlySuperAllowed?: boolean,
  onlyAdminAllowed?: boolean,
  menuName?: TranslateResult,
};

export const Route: Record<string, RouteType> = {
  HOME: {
    code: 'HOME',
    name: 'Home',
    isMenu: true,
    onlySuperAllowed: false,
    onlyAdminAllowed: false,
    menuName: i18n.t('HOME'),
  },
  SIGN_IN: {
    code: 'SIGN_IN',
    name: 'SignIn',
    isMenu: false,
    menuName: i18n.t('SIGNIN'),
  },
  ENTRY: {
    code: 'ENTRY',
    name: 'Entry',
    isMenu: true,
    onlySuperAllowed: false,
    onlyAdminAllowed: false,
    menuName: i18n.t('ENTRIES'),
  },
  ENTRY_MANUAL_INTERVENTION: {
    code: 'ENTRY_MANUAL_INTERVENTION',
    name: 'EntryManualIntervention',
    isMenu: false,
    onlySuperAllowed: false,
    onlyAdminAllowed: false,
    menuName: i18n.t('ENTRY_MANUAL_INTERVENTION'),
  },
  ENTRY_SEARCH: {
    code: 'ENTRY_SEARCH',
    name: 'EntrySearch',
    isMenu: true,
    onlySuperAllowed: false,
    onlyAdminAllowed: false,
    menuName: i18n.t('ENTRY_SEARCH'),
  },
  ENTRY_LEGACY_IMPORT: {
    code: 'ENTRY_LEGACY_IMPORT',
    name: 'EntryLegacyImport',
    isMenu: false,
    onlySuperAllowed: false,
    onlyAdminAllowed: false,
    menuName: i18n.t('IMPORT_LEGACY'),
  },
  USERS: {
    code: 'USERS',
    name: 'Users',
    isMenu: true,
    onlySuperAllowed: false,
    onlyAdminAllowed: true,
    menuName: i18n.t('USERS'),
  },
  CUSTOMERS: {
    code: 'CUSTOMERS',
    name: 'Customers',
    isMenu: true,
    onlySuperAllowed: true,
    menuName: i18n.t('CUSTOMERS'),
  },
  PERMISSION_GROUPS: {
    code: 'PERMISSION_GROUPS',
    name: 'PermissionGroups',
    isMenu: true,
    onlySuperAllowed: false,
    onlyAdminAllowed: true,
    menuName: i18n.t('PERMISSION_GROUPS'),
  },
  FEATURES: {
    code: 'FEATURES',
    name: 'Features',
    isMenu: true,
    onlySuperAllowed: true,
    menuName: i18n.t('FEATURES'),
  },
  ACCESS_DENIED: {
    code: 'ACCESS_DENIED',
    name: 'AccessDenied',
    isMenu: false,
  },
  PROFILE: {
    code: 'PROFILE',
    name: 'Profile',
    isMenu: true,
    onlySuperAllowed: false,
    onlyAdminAllowed: false,
    menuName: i18n.t('PROFILE'),
  },
  RELEASE_NOTES: {
    code: 'RELEASE_NOTES',
    name: 'ReleaseNotes',
    isMenu: true,
    onlySuperAllowed: false,
    onlyAdminAllowed: false,
    menuName: i18n.t('RELEASE_NOTES'),
  },
  // STATION_CONFIG: {
  //   code: 'STATION_CONFIG',
  //   name: 'StationConfig',
  //   isMenu: false,
  //   menuName: i18n.t('STATION_CONFIG'),
  // },
  LICENSE_MANAGER: {
    code: 'LICENSE_MANAGER',
    name: 'LicenseManager',
    isMenu: true,
    menuName: i18n.t('LICENSE_MANAGER'),
  },
  DASHBOARD: {
    code: 'DASHBOARD',
    name: 'Dashboard',
    isMenu: false,
    menuName: i18n.t('DASHBOARD'),
  },
  COLETA_SIMPLIFICADA: {
    code: 'COLETA_SIMPLIFICADA',
    name: 'ColetaSimplificada',
    isMenu: false,
    isReport: true,
  },
  COLETA_PRESENCIAL: {
    code: 'COLETA_PRESENCIAL',
    name: 'ColetaPresencial',
    isMenu: false,
    isReport: true,
  },
  DIGITALIZAZAO_FICHAS: {
    code: 'DIGITALIZAZAO_FICHAS',
    name: 'DigitalizacaoFichas',
    isMenu: false,
    isReport: true,
  },
  CADASTROS_FINALIZADOS: {
    code: 'CADASTROS_FINALIZADOS',
    name: 'CadastrosFinalizados',
    isMenu: false,
    isReport: true,
  },
  TOTAL_AUTENTICACOES: {
    code: 'TOTAL_AUTENTICACOES',
    name: 'TotalAutenticacoes',
    isMenu: false,
    isReport: true,
  },
  CADASTROS_POR_OPERADOR: {
    code: 'CADASTROS_POR_OPERADOR',
    name: 'CadastrosPorOperador',
    isMenu: false,
    isReport: true,
  },
  TOTAL_AUTENTICACOES_FALHAS: {
    code: 'TOTAL_AUTENTICACOES_FALHAS',
    name: 'TotalAutenticacoesFalhas',
    isMenu: false,
    isReport: true,
  },
  PENDENTES_ENVIO_BCA: {
    code: 'PENDENTES_ENVIO_BCA',
    name: 'PendentesEnvioParaBCA',
    isMenu: false,
    isReport: true,
  },
  COLETA_PRESENCIAL_COM_ANOMALIA: {
    code: 'COLETA_PRESENCIAL_COM_ANOMALIA',
    name: 'ColetaPresencialComAnomalia',
    isMenu: false,
    isReport: true,
  },
  COLETA_PRESENCIAL_POR_LOCALIDADE: {
    code: 'COLETA_PRESENCIAL_POR_LOCALIDADE',
    name: 'ColetaPresencialPorLocalidade',
    isMenu: false,
    isReport: true,
  },
  CADASTROS_EXCLUIDOS_POR_LOCALIDADE: {
    code: 'CADASTROS_EXCLUIDOS_POR_LOCALIDADE',
    name: 'CadastrosExcluidosLocalidade',
    isMenu: false,
    isReport: true,
  },
  LOGINS_POR_SISTEMA: {
    code: 'LOGINS_POR_SISTEMA',
    name: 'LoginsPorSistema',
    isMenu: false,
    isReport: true,
  },
  TEMPO_MEDIO_ATENDIMENTO: {
    code: 'TEMPO_MEDIO_ATENDIMENTO',
    name: 'TempoMedioDeAtendimento',
    isMenu: false,
    isReport: true,
  },
  TEMPO_MEDIO_ATENDIMENTO_LOCALIDADE: {
    code: 'TEMPO_MEDIO_ATENDIMENTO_LOCALIDADE',
    name: 'TempoMedioDeAtendimentoPorLocalidade',
    isMenu: false,
    isReport: true,
  },
  TEMPO_MEDIO_ATENDIMENTO_OPERADOR: {
    code: 'TEMPO_MEDIO_ATENDIMENTO_OPERADOR',
    name: 'TempoMedioDeAtendimentoPorOperador',
    isMenu: false,
    isReport: true,
  },
  QUALIDADE_IMPRESSOES: {
    code: 'QUALIDADE_IMPRESSOES',
    name: 'QualidadeImpressoes',
    isMenu: false,
    isReport: true,
  },
  DISTRIBUICAO_CADASTROS_ANOMALIAS: {
    code: 'DISTRIBUICAO_CADASTROS_ANOMALIAS',
    name: 'DistribuicaoCadastrosAnomalias',
    isMenu: false,
    isReport: true,
  },
  ADJUSTMENT_DUPLICATE_ENTRIES: {
    code: 'ADJUSTMENT_DUPLICATE_ENTRIES',
    name: 'AdjustmentDuplicateEntries',
    isMenu: false,
  },
  RECORD_REUSAGE_REPORT: {
    code: 'RECORD_REUSAGE_REPORT',
    name: 'RecordReusageReport',
    isMenu: false,
  },
  SETTINGS: {
    code: 'SETTINGS',
    name: 'Settings',
    isMenu: true,
  },
  FIRST_STEPS: {
    code: 'FIRST_STEPS',
    name: 'First steps',
    isMenu: true,
  },
  RECORD_RECOVERY: {
    code: 'RECORD_RECOVERY',
    name: 'RecordRecovery',
    isMenu: false,
  },
};

const routes: RouteConfig[] = [
  {
    path: '/',
    name: Route.HOME.name,
    component: async (): Promise<typeof import('../pages/Home/Home.vue')> => import(/* webpackChunkName: "home" */ '../pages/Home/Home.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin || user.menuCodes.find((menuCode) => menuCode === Route.HOME.code)) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/access-denied',
    name: Route.ACCESS_DENIED.name,
    component: async (): Promise<typeof import('../pages/Core/AccessDenied.vue')> => import(/* webpackChunkName: "home" */ '../pages/Core/AccessDenied.vue'),
  },
  {
    path: '/sign-in',
    name: Route.SIGN_IN.name,
    component: async (): Promise<typeof import('../pages/Auth/Auth.vue')> => import(/* webpackChunkName: "auth" */ '../pages/Auth/Auth.vue'),
  },
  {
    path: '/entry',
    name: Route.ENTRY.name,
    component: async (): Promise<typeof import('../pages/Entry/Entry.vue')> => import(/* webpackChunkName: "entry" */ '../pages/Entry/Entry.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin || user.menuCodes.find((menuCode) => menuCode === Route.ENTRY.code)) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/entry-manual-intervention',
    name: Route.ENTRY_MANUAL_INTERVENTION.name,
    component: async (): Promise<typeof import('../pages/ManualIntervention/ManualIntervention.vue')> => import(/* webpackChunkName: "entry-manual-intervention" */ '../pages/ManualIntervention/ManualIntervention.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin || user.menuCodes.find((menuCode) => menuCode === Route.ENTRY_MANUAL_INTERVENTION.code)) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/entry-import-legacy',
    name: Route.ENTRY_LEGACY_IMPORT.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "entry-import-legacy" */ '../pages/ImportLegacyEntry/ImportLegacyEntry.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin || user.menuCodes.find((menuCode) => menuCode === Route.ENTRY_LEGACY_IMPORT.code)) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/release-notes',
    name: Route.RELEASE_NOTES.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "release-notes" */ '../pages/ReleaseNotes/ReleaseNotes.vue'),
  },
  {
    path: '/entry-search',
    name: Route.ENTRY_SEARCH.name,
    component: async (): Promise<typeof import('../pages/Entry/EntryDetails.vue')> => import(/* webpackChunkName: "entry" */ '../pages/Entry/EntryDetails.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isOnlySuperAdmin || user.menuCodes.find((menuCode) => menuCode === Route.ENTRY_SEARCH.code)) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/users',
    name: Route.USERS.name,
    component: async (): Promise<typeof import('../pages/Users/Users.vue')> => import(/* webpackChunkName: "users" */ '../pages/Users/Users.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/customers',
    name: Route.CUSTOMERS.name,
    component: async (): Promise<typeof import('../pages/Customers/Customers.vue')> => import(/* webpackChunkName: "customers" */ '../pages/Customers/Customers.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isOnlySuperAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/permission-groups',
    name: Route.PERMISSION_GROUPS.name,
    component: async (): Promise<typeof import('../pages/PermissionGroups/PermissionGroups.vue')> => import(/* webpackChunkName: "permission-groups" */ '../pages/PermissionGroups/PermissionGroups.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  // {
  //   path: '/features',
  //   name: Route.FEATURES.name,
  //   component: async (): Promise<typeof import('../pages/Features/Features.vue')> => import(/* webpackChunkName: "features" */ '../pages/Features/Features.vue'),
  //   beforeEnter: async function (to, from, next) {
  //     const user = await getUser();
  //     if (user.isOnlySuperAdmin) {
  //       next();
  //     } else if (user.details && user.details.account.id) {
  //       next('/access-denied');
  //     }
  //   },
  // },
  {
    path: '/remove-duplicate-record',
    name: Route.ADJUSTMENT_DUPLICATE_ENTRIES.name,
    component: async (): Promise<typeof import('../pages/AdjustmentDuplicateEntries/AdjustmentDuplicateEntries.vue')> => import(/* webpackChunkName: "users" */ '../pages/AdjustmentDuplicateEntries/AdjustmentDuplicateEntries.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isOnlySuperAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/first_steps',
    name: Route.FIRST_STEPS.name,
    component: async (): Promise<typeof import('../pages/Customers/CustomerFirstSteps.vue')> => import(/* webpackChunkName: "users" */ '../pages/Customers/CustomerFirstSteps.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isOnlySuperAdmin || user.isAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/settings',
    name: Route.SETTINGS.name,
    component: async (): Promise<typeof import('../pages/Customers/CustomerSettings.vue')> => import(/* webpackChunkName: "users" */ '../pages/Customers/CustomerSettings.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isOnlySuperAdmin || user.isAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/record_recovery',
    name: Route.RECORD_RECOVERY.name,
    component: async (): Promise<typeof import('../pages/RecordRecovery/RecordRecovery.vue')> => import(/* webpackChunkName: "users" */ '../pages/RecordRecovery/RecordRecovery.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/record-reusage-report',
    name: Route.RECORD_REUSAGE_REPORT.name,
    component: async (): Promise<typeof import('../pages/RecordReusageReport/RecordReusageReport.vue')> => import(/* webpackChunkName: "users" */ '../pages/RecordReusageReport/RecordReusageReport.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isOnlySuperAdmin) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  {
    path: '/profile',
    name: Route.PROFILE.name,
    component: async (): Promise<typeof import('../pages/Profile/Profile.vue')> => import(/* webpackChunkName: "profile" */ '../pages/Profile/Profile.vue'),
    beforeEnter: async function (to, from, next) {
      const user = await getUser();
      if (user.isAdmin || user.menuCodes.find((menuCode) => menuCode === Route.PROFILE.code)) {
        next();
      } else if (user.details && user.details.account.id) {
        next('/access-denied');
      }
    },
  },
  // {
  //   path: '/station-config',
  //   name: Route.STATION_CONFIG.name,
  //   component: async (): Promise<any> => import(/* webpackChunkName: "stationConfig" */ '../pages/StationConfig/StationConfig.vue'),
  //   beforeEnter: async function (to, from, next) {
  //     const user = await getUser();
  //     if (user.isOnlySuperAdmin || user.menuCodes.find((menuCode) => menuCode === Route.STATION_CONFIG.code)) {
  //       next();
  //     } else if (user.details && user.details.account.id) {
  //       next('/access-denied');
  //     }
  //   },
  // },
  {
    path: '/license-manager',
    name: Route.LICENSE_MANAGER.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "licenseManager" */ '../pages/LicenseManager/LicenseManager.vue'),
  },
  {
    path: '/dashboard',
    name: Route.DASHBOARD.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "dashboard" */ '../pages/Dashboard/Dashboard.vue'),
  },
  {
    path: '/coleta-simplificada',
    name: Route.COLETA_SIMPLIFICADA.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "coletaSimplificada" */ '../reports/ColetaSimplificada.vue'),
  },
  {
    path: '/coleta-presencial',
    name: Route.COLETA_PRESENCIAL.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "coletaPresencial" */ '../reports/ColetaPresencial.vue'),
  },
  {
    path: '/digitalizacao-fichas',
    name: Route.DIGITALIZAZAO_FICHAS.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "digitalizacaoFichas" */ '../reports/DigitalizacaoFicha.vue'),
  },
  {
    path: '/cadastros-finalizados',
    name: Route.CADASTROS_FINALIZADOS.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "cadastrosFinalizados" */ '../reports/CadastrosFinalizados.vue'),
  },
  {
    path: '/total-autenticacoes',
    name: Route.TOTAL_AUTENTICACOES.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "totalAutenticacoes" */ '../reports/TotalAutenticacoes/TotalAutenticacoes.vue'),
  },
  {
    path: '/cadastros-por-operador',
    name: Route.CADASTROS_POR_OPERADOR.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "CadastrosPorOperador" */ '../reports/CadastrosPorOperador/CadastrosPorOperador.vue'),
  },
  {
    path: '/total-autenticacoes-falhas',
    name: Route.TOTAL_AUTENTICACOES_FALHAS.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "totalAutenticacoesFalhas" */ '../reports/TotalAutenticacoesFalhas/TotalAutenticacoesFalhas.vue'),
  },
  {
    path: '/pendentes-envio-para-bca',
    name: Route.PENDENTES_ENVIO_BCA.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "pendentesEnvioParaBCA" */ '../reports/PendentesEnvioParaBCA.vue'),
  },
  {
    path: '/coleta-presencial-com-anomalia',
    name: Route.COLETA_PRESENCIAL_COM_ANOMALIA.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "coletaPresencialComAnomalia" */ '../reports/ColetaPresencialAnomalia.vue'),
  },
  {
    path: '/coleta-presencial-agrupada-por-localidade',
    name: Route.COLETA_PRESENCIAL_POR_LOCALIDADE.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "coletaPresencialPorOperador" */ '../reports/ColetaPresencialLocalidade/ColetaPresencialLocalidade.vue'),
  },
  {
    path: '/cadastros-excluidos-por-localidade',
    name: Route.CADASTROS_EXCLUIDOS_POR_LOCALIDADE.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "cadastrosExcluidosLocalidade" */ '../reports/CadastrosExcluidosLocalidade/CadastrosExcluidosLocalidade.vue'),
  },
  {
    path: '/logins-realizados-por-sistema',
    name: Route.LOGINS_POR_SISTEMA.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "loginsPorSistema" */ '../reports/LoginsPorSistema/LoginsPorSistema.vue'),
  },
  {
    path: '/tempo-medio-de-atendimento',
    name: Route.TEMPO_MEDIO_ATENDIMENTO.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "TempoMedioDeAtendimento" */ '../reports/TempoMedioAtendimento.vue'),
  },
  {
    path: '/tempo-medio-de-atendimento-por-localidade',
    name: Route.TEMPO_MEDIO_ATENDIMENTO_LOCALIDADE.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "TempoMedioDeAtendimentoPorLocalidade" */ '../reports/TempoAtendimentoLocalidade/TempoMedioAtendimentoLocalidade.vue'),
  },
  {
    path: '/tempo-medio-de-atendimento-por-operador',
    name: Route.TEMPO_MEDIO_ATENDIMENTO_OPERADOR.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "TempoMedioDeAtendimentoPorOperador" */ '../reports/TempoAtendimentoOperador/TempoMedioAtendimentoOperador.vue'),
  },
  {
    path: '/qualidade-das-impressoes-digitais',
    name: Route.QUALIDADE_IMPRESSOES.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "QualidadeImpressoes" */ '../reports/QualidadeImpressoes/QualidadeImpressoes.vue'),
  },
  {
    path: '/distribuicao-de-cadastros-com-anomalias',
    name: Route.DISTRIBUICAO_CADASTROS_ANOMALIAS.name,
    component: async (): Promise<any> => import(/* webpackChunkName: "DistribuicaoCadastrosAnomalias" */ '../reports/DistribuicaoCadastrosAnomalias/DistribuicaoCadastrosAnomalias.vue'),
  },
];

const router = new VueRouter({
  routes: routes,
});

export default router;
