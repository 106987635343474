<template>
  <v-overlay z-index='99999' absolute :value='isLoading' class='align-center justify-center'>
    <v-card class='pa-5 d-flex-center' style='background: white; width: 200px'>
      <v-progress-circular indeterminate :width='2' color='secondary' style='width: 100%; height: 90px' />
      <br>
      <br>
      <div style='width: 100%; color: black; text-align: center;'>
        <span> {{ loadingDescription || $t("LOADING") }} </span>
      </div>
    </v-card>
    <!-- <v-progress-circular indeterminate size='64' /> -->
  </v-overlay>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'Loading',
    computed: {
      ...mapGetters('loading', [
        'isLoading',
        'loadingDescription',
      ]),
    },
  };
</script>
