var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('FIRST_STEPS'))+" ")])]),_c('v-divider',{staticClass:"my-3"}),_c('v-card',{staticClass:"pa-10"},[_c('v-card-text',[_c('ol',[_c('li',[_vm._v(_vm._s(_vm.$t('DOWNLOAD_INSTALLER')))]),_c('li',[_vm._v(_vm._s(_vm.$t('INSTALL_SOFTWARE_INFO')))]),_c('li',[_vm._v(_vm._s(_vm.$t('ACCESS_URL_TO_START_INFO')))]),_c('li',[_vm._v(_vm._s(_vm.$t('ENJOY_SOFTWARE_INFO')))])])]),_vm._l((_vm.customerServices),function(customerService){return _c('v-card-text',{key:customerService.id},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-img',{staticClass:"mr-3",attrs:{"src":_vm.getImageUrl(customerService.image),"max-width":"48","contain":""}}),_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(customerService.name))])],1),_c('v-divider',{staticClass:"my-4"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getTableHeaders(customerService),"items":_vm.customerServices.filter(function (cs) { return cs.id === customerService.id; }),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.installer",fn:function(ref){return [_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.downloadInstaller(customerService.name)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi mdi-download")]),_vm._v(" Baixar instalador ")],1)]}},{key:"item.appUrl",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"underline","color":"blue"},attrs:{"href":item.appUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t(item.appUrl)))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.copyUrl(item.appUrl)}}},on),[_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"small":""}},[_vm._v(" mdi mdi-content-copy ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('CLICK_TO_COPY_URL'))+" ")])]}},{key:"item.selfServiceUrl",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"underline","color":"blue"},attrs:{"href":item.selfServiceUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t(item.selfServiceUrl)))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.copyUrl(item.selfServiceUrl)}}},on),[_c('v-icon',{staticClass:"ml-1 mb-1",attrs:{"small":""}},[_vm._v(" mdi mdi-content-copy ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('CLICK_TO_COPY_URL'))+" ")])]}}],null,true)})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }