<template>
  <v-container fill-height fluid style='width: 85vw'>
    <v-col cols='12' class='d-flex-center'>
      <v-checkbox v-model='allowUserChangeConfigs' :label='$t("ALLOW_USER_TO_CHANGE_CONFIG")' :messages='allowUserChangeConfigs ? $t("ALLOW_USER_CHANGE_CONFIG_INFO") : ""' />
    </v-col>
    <v-row no-gutters align='center' justify='center'>
      <v-btn-toggle v-model='btnToggle'>
        <v-row v-for='captureModule in captureModules' :key='captureModule.code' no-gutters class='mt-2'>
          <v-btn class='d-flex-center pa-5' text color='primary' @click='selectedModule = captureModule.code'>
            <v-row no-gutters>
              <v-col cols='12'>
                <v-icon v-if='captureModule.icon'>{{ captureModule.icon }}</v-icon>
                <img v-else width='24' height='24' :src='require(`@/assets/icons/${captureModule.image}`)'>
              </v-col>
              <v-col cols='12' style='text-align: center'>
                {{ $t(captureModule.name) }}
              </v-col>
            </v-row>
          </v-btn>
        </v-row>
      </v-btn-toggle>
      <v-card-text>
        <CustomerCaptureModuleConfigs ref='customerCaptureModuleConfigs' :key='configskey' :configs='customerConfigs || defaultConfigs' :selected-module='selectedModule' style='max-height: 46vh; overflow-y: auto' @updated-configs='updateConfigs'>
          <template v-if='selectedModule === "palm"' #default>
            <v-col class='mb-8' cols='12'>
              <v-row class='justify-center' no-gutters>
                <v-col v-for='(_, i) in 3' :key='i' cols='4' class='px-2'>
                  <v-col class='d-flex-center bg-primary' cols='12' style='border-top-left-radius: 8px; border-top-right-radius: 8px'>
                    {{ toCapitalCase(Object.keys(palmCaptureTypes[i])[0]) }}
                  </v-col>
                  <v-col
                    v-for='(captureItem, j) in palmCapturesList[i]'
                    :key='j' class='d-flex-center'
                    :class='{
                      "title-container": captureItem.hasOwnProperty("type"),
                      "item-container": !captureItem.hasOwnProperty("type"),
                      "last": j === palmCapturesList[i].length - 1,
                    }'
                    cols='12'
                  >
                    {{ $t(captureItem.text) }}
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </CustomerCaptureModuleConfigs>
      </v-card-text>
    </v-row>
    <v-card-actions class='wd-100 mb-5'>
      <v-col>
        <v-btn depressed block color='secondary' outlined @click='resetConfigurations();'>
          {{ $t('CANCEL') }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn depressed block color='secondary' @click='saveCaptureModule()'>
          {{ $t('SAVE') }}
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';

  import { toCapitalCase } from '@/util/string';
  import CustomerCaptureModuleConfigs from './CustomerCaptureModuleConfigs';

  export default {
    name: 'CustomerCaptureModule',
    components: {
      CustomerCaptureModuleConfigs,
    },
    computed: {
      ...mapGetters('auth', ['getUser']),
      captureTypes: function () {
        const target = this.customerConfigs || this.defaultConfigs;
        return target?.palm?.extraFingerCapture || [];
      },
    },
    data: function () {
      return {
        allowUserChangeConfigs: false,
        captureModules: [
          {
            code: 'face',
            name: 'FACE_PHOTO',
            icon: 'mdi-account-box-outline',
          },
          {
            code: 'finger',
            name: 'FINGERPRINT',
            icon: 'mdi-fingerprint',
          },
          // {
          //   code: 'infant',
          //   name: 'INFANT_FINGERPRINT',
          //   image: 'infant_hand.png',
          // },
          {
            code: 'signature',
            name: 'SIGNATURE',
            icon: 'mdi-draw-pen',
          },
          {
            code: 'mugshot',
            name: 'MUGSHOT',
            image: 'mugshot.png',
          },
          {
            code: 'palm',
            name: 'PALM',
            icon: 'mdi-hand-back-right-outline',
          },
        ],
        defaultConfigs: {
          face: {
            preset: 1,
            aperture: 0,
            isoValue: 6,
            imageType: 2,
            ringlight: 0,
            flashWidth: 300,
            imageFilter: 1,
            shutterSpeed: 30,
            whiteBalance: 9,
            flashProperty: 1,
          },
          palm: {
            extraFingerCapture: 0,
          },
          finger: {
            flowType: 5,
            maxNfiqScore: 4,
            allowManualSave: 1,
            repetitionControl: 1,
          },
          signature: {
            penColor: 2,
            imageType: 'png',
          },
        },
        customerConfigs: undefined,
        selectedModule: undefined,
        btnToggle: 0,
        configskey: 0,
        palmCaptureTypes: [
          { COMPLETA: 1 },
          { INTERMEDIÁRIA: 2 },
          { REDUZIDA: 0 },
        ],
        palmCapturesList: [
          [
            {
              text: 'THUMBS',
            },
            {
              type: 'divider',
              text: 'RIGHT_HAND',
            },
            {
              text: 'RIGHT_PALM',
            },
            {
              text: 'RIGHT_HYPOTHENAR',
            },
            {
              text: 'RIGHT_HYPOTHENAR_PALM',
            },
            {
              text: 'RIGHT_PINKY_RING_FINGERS',
            },
            {
              text: 'RIGHT_MIDDLE_INDEX_FINGERS',
            },
            {
              text: 'RIGHT_THUMB_FINGER',
            },
            {
              text: 'RIGHT_PALM_FINGERS',
            },
            {
              type: 'divider',
              text: 'LEFT_HAND',
            },
            {
              text: 'LEFT_PALM',
            },
            {
              text: 'LEFT_HYPOTHENAR',
            },
            {
              text: 'LEFT_HYPOTHENAR_PALM',
            },
            {
              text: 'LEFT_PINKY_RING_FINGERS',
            },
            {
              text: 'LEFT_MIDDLE_INDEX_FINGERS',
            },
            {
              text: 'LEFT_THUMB_FINGER',
            },
            {
              text: 'LEFT_PALM_FINGERS',
            },
          ],
          [
            {
              text: 'THUMBS',
            },
            {
              type: 'divider',
              text: 'RIGHT_HAND',
            },
            {
              text: 'RIGHT_PALM',
            },
            {
              text: 'RIGHT_HYPOTHENAR',
            },
            {
              text: 'RIGHT_PALM_FINGERS',
            },
            {
              type: 'divider',
              text: 'LEFT_HAND',
            },
            {
              text: 'LEFT_PALM',
            },
            {
              text: 'LEFT_HYPOTHENAR',
            },
            {
              text: 'LEFT_PALM_FINGERS',
            },
          ],
          [
            {
              type: 'divider',
              text: 'RIGHT_HAND',
            },
            {
              text: 'RIGHT_PALM',
            },
            {
              text: 'RIGHT_HYPOTHENAR',
            },
            {
              type: 'divider',
              text: 'LEFT_HAND',
            },
            {
              text: 'LEFT_PALM',
            },
            {
              text: 'LEFT_HYPOTHENAR',
            },
          ],
        ],
      };
    },
    mounted () {
      this.getCustomerModules();
      this.selectedModule = this.captureModules?.length ? this.captureModules[0].code : '';
    },
    methods: {
      toCapitalCase,
      resetConfigurations: function () {
        this.getCustomerModules();
        this.$refs.customerCaptureModuleConfigs.updateWithCustomerData();
        this.configskey++;
      },
      getCustomerModules: async function () {
        const { data } = await axios({
          url: `/customer/${this.getUser.account.customerUuid}/configuration/module`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        this.customerConfigs = data.module;
        this.allowUserChangeConfigs = data.allowUserChange;
      },
      saveCaptureModule: function () {
        Swal.fire({
          title: 'Aviso',
          text: 'Deseja salvar o fluxo de coleta? Esta alteração irá refletir nas estações de captura.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Salvar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.value) {
            await axios({
              url: `/customer/${this.getUser.account.customerUuid}/configuration/module`,
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'POST',
              responseType: 'json',
              withCredentials: true,
              data: {
                allowUserChangeConfigs: this.allowUserChangeConfigs,
                data: this.customerConfigs,
              },
            });
          }
        });
      },
      updateConfigs: function (configs) {
        this.customerConfigs = configs;
        this.$forceUpdate();
      },
    },
  };
</script>

<style scoped>
  .capture-type-container {
    border-radius: 8px;
    border: 1px solid #D9D9D9;
  }

  .title-container {
    background-color: #996cd3;
    color: white;
  }

  .item-container {
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    align-items: flex-start;
  }

  .last {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid #D9D9D9;
  }
</style>
